@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

li {
	list-style-type: none;
}

*{
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
	color: inherit;
	// font-family: Font2;
	// font-weight: bold;
}

p,
li,
a {
	font-family: Font3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $header-font;
}


@font-face {
	font-family: Font3;
	src: url('../Fonts/falling-sky-font/FallingSky-JKwK.otf');
	// font-weight: 800;
}

@font-face {
	font-family: Font5;
	src: url('../Fonts/Helvetica-Font/Helvetica-Bold.ttf');
	// font-weight: 800;
}

section {
	padding: 3% 0;
	overflow: hidden;
}

.section-title {
	text-align: center;
	padding-bottom: 30px;

	h2 {
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		color: $orange;

		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 120px;
			height: 1px;
			background: rgba($color: #000, $alpha: 0.3);
			bottom: 1px;
			left: calc(50% - 60px);
		}

		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 40px;
			height: 3px;
			background: $yellow;
			bottom: 0;
			left: calc(50% - 20px);
		}
	}

	p {
		margin-bottom: 0;
	}
}



@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.1, 1.1);
	}

	100% {
		transform: scale(1, 1);
	}
}

// animation: zoom-in-zoom-out 2s ease-out infinite

/*--------------------------------------------------------------
# Up Arrow
--------------------------------------------------------------*/
.uparrow {
	background: $gradient;
	position: fixed;
	bottom: 5%;
	right: 5%;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	z-index: 1000;
	filter: drop-shadow(2px 2px 5px $yellow);
	cursor: pointer;
	animation: slide-in-down 1s;
	transition: 1s all ease;

	svg {
		fill: #fff;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/
header {
	background: #fff;
	@include flex;
	justify-content: space-between;
	padding: 2% 5%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	box-shadow: $box-shadow;
	width: 100%;

	.logo {
		width: 30%;

		img {
			width: 50%;
			filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
		}
	}

	.links {
		width: 50%;

		a {
			font-family: $header-font;
			font-weight: bold;
			margin-right: 7%;
			padding-bottom: 1%;
			border-bottom: 2px solid #fff;

			&:last-child {
				border-radius: 40px;
				background: $gradient;
				box-shadow: 2px 2px 5px rgba($color: $yellow, $alpha: 0.3);
				backdrop-filter: blur(5px);
				-webkit-backdrop-filter: blur(5px);
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: #fff;
				padding: 1.5% 5%;
				margin-left: 5%;

				&:hover {
					color: $yellow;
					border-bottom: 2px solid $yellow;
					background: transparent;
					box-shadow: 2px 2px 10px rgba($color: $yellow, $alpha: 0.9);
				}
			}

			&:hover {
				color: $orange;
				transition: .2s all ease-in-out;
				border-bottom: 2px solid $orange;
			}
		}
	}

	.menu {
		width: 40px;
		height: 40px;
		fill: $orange;
		display: none;
		margin-right: 10%;
	}
}

#navbar-mobile {
	display: none;
	position: fixed;
	right: 0;
	top: 0;
	background: #fff;
	width: 60%;
	height: 100%;
	padding: 5%;
	background: $gradient;
	animation: slide-in-right .5s;

	.links {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 30%;

		a {
			margin-bottom: 10%;
			font-size: 4.5vw;
			color: #fff;
			border: none;

			&:last-child {
				background: transparent;
				color: #fff;
				border-radius: 0;
				margin-left: 0;
				padding: 0;
				border: none;
				box-shadow: none;
			}
		}
	}

	.cross {
		width: 30px;
		height: 30px;
		padding: 2%;
		fill: #fff;
		margin-left: auto;
		position: absolute;
		top: 2%;
		right: 10%;
	}
}

/*--------------------------------------------------------------
# Banner
--------------------------------------------------------------*/

.banner {
	@include flex;
	padding: 0 5%;
	margin-top: 7%;
	position: relative;

	.banner-caption {
		width: 50%;
		position: absolute;
		top: 0;
		left: 5%;

		.banner-text {
			margin: 10% 0;
			position: relative;

			img {
				width: 100%;
				z-index: -1;
				position: absolute;
				left: -10%;
			}

			p {
				font-size: 2.5vw;
				font-family: Font5;
				font-weight: 800;
				color: #fff;
				text-transform: capitalize;
				text-shadow: 1px 1px 2px rgba($color: #000, $alpha: 0.3);
				line-height: 130%;
				position: absolute;
				left: -5%;
				margin-top: 7%;
				margin-bottom: 0;
			}
		}

		.button {
			background: #fff;
			color: #fff;
			@include flex;
			width: 30%;
			border-radius: 30px;
			padding: 3% 5%;
			border-top: 5px solid $yellow;
			position: absolute;
			top: 550%;


			svg {
				fill: $orange;
				width: 25px;
				height: 25px;
				margin-right: 10%;
			}

			a {
				text-transform: uppercase;
				font-size: 1.5vw;
				margin-bottom: 0;
				font-weight: bold;
				font-family: $header-font;
				font-style: normal;
				letter-spacing: 1px;
				color: $orange;

			}
		}
	}

	.banner-img {
		width: 50%;
		position: relative;

		right: -5%;
		img {
			width: 60%;
			object-fit: contain;
			padding: 10%;
			margin-left: 50%;
		}
	}
}

.wave {
	margin-top: -2%;
	z-index: -1;
	position: relative;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2% 5% 2% 5%;


	.about-img {
		margin-right: 5%;
		// padding:%;
		width: 20%;

		img {
			width: 80%;
			object-fit: contain;
			filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
			margin: auto;
			display: block;

			// rotate: -1deg;
			&:hover {
				transform: scale(1.1);
				transition: $transition;
			}
		}
	}


	.icon-boxes {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		width: 80%;


		h3 {
			font-size: 2vw;
			font-weight: 700;
			color: $orange;
			margin-top: 0;
			margin-bottom: 0;
		}

		p {
			margin-bottom: 2%;
			font-size: 1.2vw;
			line-height: 150%;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		}

		.icon-box {
			margin-top: 3%;

			.icon {
				float: left;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 45px;
				height: 45px;
				border: 2px solid $yellow;
				border-radius: 50px;
				transition: 0.5s;

				svg {
					fill: $yellow;
					padding: 15%;
				}
			}

			&:hover {
				.icon {
					background: $yellow;
					border-color: $yellow;

					svg {
						fill: #fff;
					}
				}
			}

			.title {
				margin-left: 75px;
				font-weight: 700;
				margin-bottom: 4px;
				font-size: 1.3vw;
				color: $orange;
				transition: 0.3s;
			}

			.description {
				margin-left: 75px;
				line-height: 150%;
				font-size: 1.2vw;
				margin-top: 0;
			}
		}
	}





}

#about {
	margin-bottom: -35%;
}

/*--------------------------------------------------------------
# Benefits
--------------------------------------------------------------*/
.benefits {
	padding: 0 5% 5% 5%;

	.benefits-body {
		display: flex;
		justify-content: center;
		align-items: stretch;
		flex-wrap: wrap;

		.benefits-card {
			width: calc(100% / 3.2);
			margin: 1%;
			// height: 25vh;
			background: #fff;
			border-radius: 5px;
			display: flex;
			align-items: stretch;
			box-shadow: $box-shadow;

			&:hover {
				transition: $transition;

				.benefits-card-caption {
					width: 60%;
					transition: $transition;
				}

				img {
					width: 40%;
					transition: $transition;

				}
			}

			img {
				width: 40%;
				padding: 1%;
				border-radius: 10px;
				object-fit: cover;
				// border: 1px solid rgba($color: #000, $alpha: 0.3);

			}

			.benefits-card-caption {
				width: 60%;
				text-align: left;
				padding: 5% 3%;
				display: flex;
				justify-content: center;
				// align-items: center;
				flex-direction: column;

				h6 {
					font-size: 1.2vw;
					margin: 0 0 2% 0;
					text-align: center;
				}

				p {
					font-size: 1.1vw;
					margin-top: 0;
					margin-bottom: 0;
					line-height: 150%;
					color: rgba($color: #000, $alpha: 0.8);
					text-align: left;
					font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
				}
			}


		}
	}
}


/*--------------------------------------------------------------
# Safety Informaion
--------------------------------------------------------------*/
.safety {
	padding: 1% 5% 5% 5%;
	background: rgba($color: $orange, $alpha: 0.1);

	.safety-body {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;


		.s-c {
			padding-bottom: 3% !important;
		}

		.safety-card {
			background: #fff;
			box-shadow: 2px 2px 5px rgba($color: $orange, $alpha: 0.3);
			margin: 1% 1% 1% 1%;
			width: calc(100% / 3.3);
			// height: 32vh;
			position: relative;
			border-radius: 5px;
			border: 1px solid $yellow;
			// padding: 0 2%;

			.svg {
				width: 60px;
				height: 60px;
				position: relative;
				top: 20%;
				// left: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				// float: left;
				// background: transparent;
				border-radius: 50%;
				background: $gradient;
				margin-top: 8%;

				svg {
					fill: #fff;
					width: 35px;
					height: 35px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			p {
				text-align: center;
				font-size: 1.2vw;
				line-height: 150%;
				padding: 5% 5% 0 5%;
				font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			}

			&:hover {
				transform: scale(1.1);

				.svg {
					background: #fff;
					border: 2px solid $yellow;

					svg {
						fill: $yellow;
						transition: .5s all ease;
					}
				}

				background: $gradient;
				transition: .5s all ease;

				p {
					color: #fff;
					transition: .5s all ease;
				}
			}
		}
	}
}


/*--------------------------------------------------------------
# Indications
--------------------------------------------------------------*/
.indications {
	background: rgba($color: $orange, $alpha: 0.1);
	padding: 0 5% 5% 5%;

	.indications-body {
		@include flex;
		align-items: stretch;
	}

	.member {
		position: relative;
		box-shadow: 2px 2px 5px rgba($color: #000, $alpha: 0.3);
		border-radius: 10px;
		width: calc(100% / 4.2);
		margin: 1%;
		padding: 0;
		background: #fff;

		.pic {

			overflow: hidden;

			img {
				transition: ease-in-out 0.3s;
				width: 100%;
			}
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		.member-info {
			padding-top: 40%;
			padding: 5%;
		}

		h4 {
			font-weight: 700;
			margin-bottom: 5px;
			font-size: 1vw;
			text-align: center;
			text-transform: capitalize;
			margin-top: 0;
		}
	}
}

/*--------------------------------------------------------------
# Buy
--------------------------------------------------------------*/
.buy {
	padding: 0 5%;
	position: relative;

	img {
		width: 40%;
		position: absolute;
		top: 0;
		left: 0;
	}

	form {
		width: 55%;
		background: $buy-gradient;
		margin-left: 20%;
		box-shadow: $box-shadow;
		padding: 3% 3% 3% 20%;
		height: 80%;
		margin-bottom: 5%;

		.row {
			display: flex;
			justify-content: space-between;
		}

		input,
		select {
			border-radius: 0;
			box-shadow: none;
			font-size: 1.2vw;
			height: 44px;
			border-color: #fff;
			text-transform: capitalize;
			color: #000;
			background: transparent;
			border: none;
			border-bottom: 2px solid #fff;
			width: 100%;

			margin-bottom: 3%;

			&:first-child {
				margin-right: 5%;
			}

			&::placeholder {
				color: #fff;
				text-transform: capitalize;
			}

			&:focus,
			&:active {
				border-color: #fff;
				color: #000;
				outline: none;

				&::placeholder {
					color: #000;
				}
			}



		}

		select {
			// background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E") no-repeat right .5em center/15px 15px !important;
			color: #fff;

			&:focus,
			&:active {
				color: #000;
			}

			option {
				background: #fff;
				color: #000;
			}

		}


		textarea {
			border-radius: 0;
			box-shadow: none;
			font-size: 14px;
			padding: 10px 12px;

			&:focus,
			&:active {
				border-color: $orange;
				background: #fff;
			}
		}

		button{
			background: #fff;
			border: 0;
			padding: 10px 35px;
			color: #000;
			transition: 0.4s;
			border-radius: 10px;
			border: 1px solid transparent;
			margin-top: 2%;
			width: 100%;
			box-shadow: $box-shadow;
			text-shadow: 0px 0px 0px #000;

			&:hover {
				background: transparent;
				border: 2px solid #fff;
				transition: $transition;
				color: #fff;
				font-weight: bold;
			}
		}
	}
}

// #buy {
// 	margin-top: 15%;
// }


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer {
	padding: 2% 2% 0 2%;
	background: $gradient;

	.footer-body {
		display: flex;
		justify-content: center;
		align-items: stretch;
		color: #000;

		.footer-about,
		.footer-links,
		.footer-contact,
		.footer-map {
			margin: 1%;

		}

		.footer-about {
			width: 30%;

			h2 {
				font-size: 1.5vw;
				text-transform: uppercase;
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 5%;
				margin-top: 0;
			}

			p {
				font-size: 1.1vw;
				color: #000;
				margin: 0;
				line-height: 150%;
				font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			}
		}

		.footer-links {
			width: 20%;

			h4 {
				font-size: 1.5vw;
				text-transform: capitalize;
				margin-bottom: 7%;
				margin-top: 0;
			}

			.footer-links-content {
				display: flex;
				flex-direction: column;

				a {
					font-size: 1.1vw;
					text-transform: capitalize;
					margin-bottom: 3%;
					color: inherit;
					display: flex;
					align-items: center;
					font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

					&:hover {
						color: #fff;
						transform: scale(1.1) translatex(15px);
						transition: .5s all ease;

						svg {
							fill: #fff;
						}
					}

					svg {
						width: 15px;
						height: 15px;


						fill: #000;

					}
				}
			}
		}

		.footer-contact {
			width: 30%;

			.footer-follow {
				margin: 10% 0;


				.footer-follow-top {

					display: flex;
					justify-content: flex-start;
					align-items: center;

					a {
						margin-right: 3%;
						border: 1px solid #000;
						padding: 2%;
						border-radius: 5px;
						width: 20px;
						height: 20px;
						position: relative;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 15px;
							height: 15px;
							fill: #000;
						}

						&:hover {
							background: #fff;
							transition: .2s all ease;
							border-color: #fff;

							svg {
								fill: $orange;
							}
						}
					}
				}
			}

			h4 {
				font-size: 1.5vw;
				text-transform: capitalize;
				margin-bottom: 5%;
				margin-top: 0;
			}

			.footer-contact-body {

				.footer-contact-content {
					display: flex;
					align-items: center;
					margin-bottom: 2%;

					&:hover {

						svg,
						a {
							color: #fff;
							transition: $transition;
							fill: #fff;
						}



					}

					.svg {
						margin-right: 5%;

						svg {

							width: 15px;
							height: 15px;
							fill: #000;
						}
					}



					a {
						font-size: 1.1vw;
						text-transform: capitalize;
						color: #000;
						font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
					}
				}

			}


		}

		.footer-map {
			width: 30%;

			iframe {
				width: 100%;
				height: 100%;
				border: none;
			}
		}
	}

	.footer-copyright {
		display: flex;
		align-items: center;
		border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
		color: #000;
		justify-content: space-between;
		padding: 2% 0;

		p {
			font-size: 1.1vw;
			margin: 0;
			text-align: center;


			a {
				font-weight: bold;
				color: #000;
			}
		}

		.footer-copyright-content {
			width: 30%;
			color: rgba($color: #fff, $alpha: 0.7);

			a {
				font-size: 1.1vw;
				margin-right: 5%;
				color: #000;
			}
		}
	}
}

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {


	#buy {
		margin-top: -20%;
	}

	.section-title {
		h2 {
			font-size: 7vw;
		}
	}

	header {
		padding: 3% 5%;

		.logo {
			width: 80%;

			img {
				filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

			}
		}

		.links {
			display: none;
		}

		.menu {
			display: block;
		}
	}

	.banner {
		margin-top: 20%;

		.banner-caption {

			.button {
				width: 60%;
				padding: 5%;

				svg {
					width: 15px;
					height: 15px;
				}

				a {
					font-size: 3vw;
				}

			}

			.banner-text {
				p {
					margin-top: 7%;
					font-size: 2.6vw;
				}
			}
		}
	}

	.about {
		padding: 5%;
		flex-direction: column;

		.about-img {
			width: 100%;

			img {
				width: 50%;
				padding: 5%;
				margin: auto;
				display: block;
			}
		}


		.icon-boxes {
			width: 100%;

			h3 {
				margin-top: 5%;
				font-size: 5vw;
			}

			p {
				font-size: 4.3vw;
				line-height: 150%;
			}


			.icon-box {
				.title {
					font-size: 4.8vw;
				}

				.description {
					font-size: 4.3vw;
					line-height: 150%;
				}
			}
		}
	}

	.benefits {
		.benefits-body {
			flex-direction: column;

			.benefits-card {
				flex-direction: column;
				width: 100%;
				margin-bottom: 5%;
				height: 100%;

				&:hover {
					transition: $transition;

					.benefits-card-caption {
						width: 95%;
						transition: $transition;
					}

					img {
						width: 100%;
					}
				}

				img {
					width: 100%;
					padding: 0;
					border-radius: 5px 5px 0 0;
				}



				.benefits-card-caption {
					width: 95%;

					h6 {
						font-size: 4vw;
					}

					p {
						font-size: 4vw;
					}

				}
			}
		}
	}

	.safety {
		.container {
			padding: 0;
		}

		.safety-body {
			flex-wrap: wrap;

			.safety-card {
				width: 100%;

				p {
					font-size: 4vw;
				}
			}
		}
	}

	.indications {
		padding: 5%;

		.indications-body {
			flex-wrap: wrap;

			.member {
				width: 100%;
				margin-bottom: 5%;

				h4 {
					font-size: 4vw;
				}
			}
		}
	}

	.buy {

		form {
			padding: 5%;
			width: 90%;
			margin-left: 0;

			input,
			select {
				font-size: 4vw;
			}

			.row {
				flex-direction: column;
			}

			button[type=submit] {
				font-size: 4vw;
			}
		}

		img {
			display: none;
		}
	}

	
	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				h2 {
					font-size: 4.5vw;
					margin-bottom: 2%;
					font-weight: bold;
				}

				p {
					font-size: 4vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;
				margin-top: 5%;

				h4 {
					font-size: 4.5vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-links-content {
					a {
						font-size: 4vw;
					}
				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4.5vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 4vw
						}

						svg {
							width: 15px;
							height: 15px;
						}
					}
				}

				.footer-follow {
					a {
						width: 25px;
						height: 25px;

						svg {
							width: 18px;
							height: 18px;
						}
					}
				}

			}

		}

		.footer-copyright {
			display: block;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}

			.footer-copyright-content {
				width: 100%;
				text-align: center;
				margin-top: 3%;

				a {
					font-size: 3vw;
				}
			}
		}
	}
}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
	#buy {
		margin-top: -20%;
	}

	.section-title {
		margin-top: 2%;

		h2 {
			font-size: 5vw;
		}
	}

	header {
		padding: 3% 5%;

		.logo {
			width: 40%;

			img {
				filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

			}
		}

		.links {
			display: none;
		}

		.menu {
			display: block;
		}
	}

	.banner {
		margin-top: 15%;

		.banner-caption {

			.button {
				width: 60%;
				padding: 5%;

				a {
					font-size: 3vw;
				}

			}

			.banner-text {
				p {
					margin-top: 7%;
					font-size: 2.6vw;
				}
			}
		}
	}

	.about {
		padding: 5%;
		flex-direction: column;

		.about-img {
			width: 100%;

			img {
				width: 50%;
				padding: 5%;
				margin: auto;
				display: block;
			}
		}


		.icon-boxes {
			width: 100%;

			h3 {
				margin-top: 5%;
				font-size: 5vw;
			}

			p {
				font-size: 2.5vw;
				line-height: 150%;
			}

		}
	}

	.benefits {
		.benefits-body {
			flex-wrap: wrap;

			.benefits-card {
				width: calc(100% / 2.1);
				margin-bottom: 1%;
				height: 20vh;

				&:hover {
					transition: $transition;

					.benefits-card-caption {
						width: 95%;
						transition: $transition;
					}

					img {
						width: 30%;
					}
				}

				img {
					width: 30%;
					padding: 0;
					border-radius: 5px 0 0 5px;
				}

				.benefits-card-caption {
					width: 95%;

					h6 {
						font-size: 2.5vw;
					}

					p {
						font-size: 1.8vw;
					}



				}
			}
		}
	}

	.safety {
		.container {
			padding: 0;
		}

		.safety-body {
			flex-wrap: wrap;

			.safety-card {
				width: calc(100% / 3.3);

				p {
					font-size: 1.5vw;
				}
			}
		}
	}

	.indications {
		padding: 5%;

		.indications-body {
			flex-wrap: wrap;

			.member {
				width: calc(100% / 2.1);
				margin-bottom: 5%;

				h4 {
					font-size: 2.3vw;
				}
			}
		}
	}

	.buy {

		form {
			padding: 5%;
			width: 90%;
			margin-left: 0;

			input,
			select {
				font-size: 2.5vw;
			}

			.row {
				flex-direction: column;
			}

			button {
				font-size: 3vw;
			}
		}

		img {
			display: none;
		}
	}



	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				h2 {
					font-size: 4vw;
					margin-bottom: 2%;
				}

				p {
					font-size: 3.5vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-links-content {
					a {
						font-size: 3.5vw;
						display: flex;
						align-items: center;

						svg {
							width: 25px;
							height: 25px;
						}
					}


				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 3.5vw
						}

						svg {
							width: 25px;
							height: 25px;
						}
					}
				}

				.footer-follow {
					.footer-follow-top {
						a {
							width: 25px;
							height: 25px;

							svg {
								width: 20px;
								height: 20px;
							}
						}
					}

				}

			}

		}

		.footer-copyright {
			display: block;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}

			.footer-copyright-content {
				width: 100%;
				text-align: center;

				a {
					font-size: 3vw;
				}
			}
		}
	}
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

	#buy {
		margin-top: -20%;
	}


	.section-title {
		h2 {
			font-size: 7vw;
		}
	}

	header {
		padding: 3% 5%;

		.logo {
			width: 80%;

			img {
				filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));

			}
		}

		.links {
			display: none;
		}

		.menu {
			display: block;
		}
	}

	.banner {
		margin-top: 20%;

		.banner-caption {

			.button {
				width: 60%;
				padding: 5%;

				svg {
					width: 25px;
					height: 25px;
				}

				a {
					font-size: 3vw;
				}

			}

			.banner-text {
				p {
					margin-top: 7%;
					font-size: 2.6vw;
				}
			}
		}
	}

	.about {
		padding: 5%;
		flex-direction: column;

		.about-img {
			width: 100%;

			img {
				width: 50%;
				padding: 5%;
				margin: auto;
				display: block;
			}
		}


		.icon-boxes {
			width: 100%;

			h3 {
				margin-top: 5%;
				font-size: 5vw;
			}

			p {
				font-size: 3.3vw;
				line-height: 150%;
			}

			.icon-box {
				.title {
					font-size: 3.8vw;
				}

				.description {
					font-size: 3.3vw;
					line-height: 150%;
				}
			}

		}
	}

	.benefits {
		.benefits-body {
			flex-direction: column;

			.benefits-card {
				flex-direction: column;
				width: 100%;
				margin-bottom: 5%;
				height: 100%;

				&:hover {
					transition: $transition;

					.benefits-card-caption {
						width: 95%;
						transition: $transition;
					}

					img {
						width: 100%;
					}
				}

				img {
					width: 100%;
					padding: 0;
					border-radius: 5px 5px 0 0;
				}

				.benefits-card-caption {
					width: 95%;

					h6 {
						font-size: 4vw;
					}

					p {
						font-size: 4vw;
					}
				}
			}
		}
	}

	.safety {
		.container {
			padding: 0;
		}

		.safety-body {
			flex-wrap: wrap;

			.safety-card {
				width: 100%;

				.svg {
					width: 80px;
					height: 80px;

					svg {
						width: 40px;
						height: 40px;
					}
				}

				p {
					font-size: 3.7vw;
				}
			}
		}
	}

	.indications {
		padding: 5%;

		.indications-body {
			flex-wrap: wrap;

			.member {
				width: 100%;
				margin-bottom: 5%;

				h4 {
					font-size: 4vw;
				}
			}
		}
	}

	.buy {

		form {
			padding: 5%;
			width: 90%;
			margin-left: 0;

			input,
			select {
				font-size: 4vw;
				margin-bottom: 7%;
			}

			select {
				option {
					font-size: 2.5vw;
					width: 50%;
				}
			}

			.row {
				flex-direction: column;
			}

			button[type=submit] {
				font-size: 4vw;
			}
		}

		img {
			display: none;
		}
	}



	footer {
		.footer-body {
			// flex-direction: column;
			flex-wrap: wrap;
			padding: 5%;

			.footer-about,
			.footer-links,
			.footer-contact,
			.footer-map {
				width: 100%;
			}

			.footer-about {
				h2 {
					font-size: 4vw;
					margin-bottom: 2%;
				}

				p {
					font-size: 3.5vw;
				}
			}

			.footer-links {
				padding-left: 0;
				margin-bottom: 2%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin: 5% 0;
				}

				.footer-links-content {
					a {
						font-size: 3.5vw;

						svg {
							width: 22px;
							height: 22px;
						}
					}
				}

			}

			.footer-contact {
				margin-bottom: 5%;
				width: 100%;

				h4 {
					font-size: 4vw;
					font-weight: bold;
					margin-bottom: 5%;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 3.5vw
						}

						svg {
							width: 22px;
							height: 22px;
						}
					}
				}

				.footer-follow {
					.footer-follow-top {
						a {
							width: 35px;
							height: 35px;

							svg {
								width: 30px;
								height: 30px;
							}
						}
					}

				}

			}

		}

		.footer-copyright {
			display: block;

			p {
				text-align: center;

				font-size: 3vw;
				width: 100%;
			}

			.footer-copyright-content {
				width: 100%;
				text-align: center;
				margin-top: 3%;

				a {
					font-size: 3vw;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {

	header {
		.links {
			width: 70%;
			text-align: center;

			a {
				margin-right: 5%;
				font-size: 2vw;
			}
		}
	}

	.about {
		padding: 5%;

		.about-img {
			width: 40%;

			img {
				width: 100%;
				padding: 0;
			}
		}

		.icon-boxes {
			h3 {
				// margin-top: 5%;
				font-size: 3vw;
			}

			p {
				font-size: 2vw;
				// line-height: 150%;
			}

			.icon-box {
				.title {
					font-size: 2.3vw;
				}

				.description {
					font-size: 2vw;
					line-height: 180%;
				}
			}
		}


	}

	.benefits {
		.container {
			max-width: 100%;
		}

		.benefits-body {
			.benefits-card {
				width: calc(100% / 2.2);

				.benefits-card-caption {
					p {
						font-size: 1.9vw;
					}
				}
			}
		}
	}

	.safety {
		.safety-body {

			// flex-wrap: nowrap;
			.safety-card {
				width: calc(100% / 3.3);

				p {
					font-size: 2vw;
				}
			}
		}

		.container {
			max-width: 100%;
		}
	}

	.indications {
		padding: 5%;

		.indications-body {
			flex-wrap: wrap;

			.member {
				width: calc(100% / 3.4);

				h4 {
					font-size: 1.8vw;
				}
			}
		}



		.container {
			max-width: 100%;
		}
	}

	.buy {
		.container {
			margin: 0 0 0 auto;
		}

		form {

			input,
			select {
				font-size: 1.8vw;
			}

			button[type=submit] {
				font-size: 2vw;
			}
		}
	}

	
	footer {
		.footer-body {

			.footer-about {
				h2 {
					font-size: 1.8vw;
				}

				p {
					font-size: 1.5vw;
				}
			}

			.footer-links {
				h4 {
					font-size: 1.8vw;
				}

				.footer-links-content {
					a {
						font-size: 1.5vw;

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
			}

			.footer-contact {
				h4 {
					font-size: 1.8vw;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 1.5vw;
						}

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

		.footer-copyright {
			p {
				font-size: 1.8vw;
			}

			.footer-copyright-content {
				a {
					font-size: 1.8vw;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

	header {
		.links {
			width: 70%;
			text-align: center;

			a {
				margin-right: 5%;
				font-size: 2vw;
			}
		}
	}

	.about {
		padding: 5%;

		.about-img {
			width: 40%;

			img {
				width: 100%;
				padding: 0;
			}
		}

		.icon-boxes {
			h3 {
				// margin-top: 5%;
				font-size: 3vw;
			}

			p {
				font-size: 2vw;
				// line-height: 150%;
			}

			.icon-box {
				.title {
					font-size: 2.3vw;
				}

				.description {
					font-size: 2vw;
					line-height: 180%;
				}
			}
		}


	}

	.benefits {
		.container {
			max-width: 100%;
		}

		.benefits-body {
			.benefits-card {
				width: calc(100% / 2.2);

				.benefits-card-caption {
					p {
						font-size: 1.9vw;
					}
				}
			}
		}
	}

	.safety {
		.safety-body {

			// flex-wrap: nowrap;
			.safety-card {
				width: calc(100% / 3.3);

				p {
					font-size: 2vw;
				}
			}
		}

		.container {
			max-width: 100%;
		}
	}

	.indications {
		padding: 5%;

		.indications-body {
			flex-wrap: wrap;

			.member {
				width: calc(100% / 3.4);

				h4 {
					font-size: 1.8vw;
				}
			}
		}



		.container {
			max-width: 100%;
		}
	}

	.buy {
		.container {
			margin: 0 0 0 auto;
		}

		form {

			input,
			select {
				font-size: 1.8vw;
			}

			button[type=submit] {
				font-size: 2vw;
			}
		}
	}

	
	

	footer {
		.footer-body {

			.footer-about {
				h2 {
					font-size: 1.8vw;
				}

				p {
					font-size: 1.5vw;
				}
			}

			.footer-links {
				h4 {
					font-size: 1.8vw;
				}

				.footer-links-content {
					a {
						font-size: 1.5vw;

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
			}

			.footer-contact {
				h4 {
					font-size: 1.8vw;
				}

				.footer-contact-body {
					.footer-contact-content {
						a {
							font-size: 1.5vw;
						}

						svg {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}

		.footer-copyright {
			p {
				font-size: 1.8vw;
			}

			.footer-copyright-content {
				a {
					font-size: 1.8vw;
				}
			}
		}
	}
}

@media (min-width: 1025px) and (max-width: $xl) {
	.wave {
		width: 100%;
	}

	header {
		.links {
			width: 60%;
			text-align: center;

			a {
				margin-right: 5%;
			}
		}
	}


}